.tour__content img {
   width        : 100%;
   border-radius: 0.5rem;
   margin-bottom: 1rem;
}

.tour__info,
.tour__reviews {
   border-radius: 0.5rem;
   border       : 1px solid var(--secondary-color);
   padding      : 1.5rem;
}

.tour__info h2 {
   font-size: 1.5rem;
}

.tour__info span {
   display    : flex;
   align-items: center;
   column-gap : 0.5rem;
   font-size  : 0.8rem;
   color      : var(--text-color);
}

.tour__info span i {
   color    : var(--secondary-color);
   font-size: 1rem;
}

.tour__extra-details {
   display      : flex;
   align-items  : center;
   column-gap   : 2.5rem;
   margin-top   : 1rem;
   margin-bottom: 2.5rem;
}

.tour__info h5 {
   margin-bottom: 1rem;
   font-size    : 1.3rem;
}

.tour__info p {
   line-height: 30px;
   font-size  : 1rem;
   color      : var(--text-color);
}

.tour__reviews h4{
   margin-bottom: 1.5rem;
}

.rating__group span {
   font-size: 1rem;
   display: flex;
   align-items: center;
   color: var(--secondary-color);
   cursor: pointer;
}

.review__input {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   padding: 0.5rem;
   border-radius: 2rem;
   border: 1px solid var(--secondary-color);
}

.review__input input {
   width: 100%;
   padding: 0.5rem 0;
   border: none;
   outline: none;
}

.user__reviews {
   margin-top: 2.5rem;
}

.review__item {
   display: flex;
   align-items: center;
   column-gap: 1rem;
   margin-bottom: 2rem;
}

.review__item img {
   width: 3.5rem;
   height: 3.5rem;
   border-radius: 50px;
   object-fit: cover;
}

.review__item h5,
.review__item p,
.review__item h6 {
   font-size: 0.8rem;
   margin-bottom: 0;
}

.review__item p,
.review__item h6 {
   color: var(--text-color);
}

.review__item  span {
   font-weight: 500;
}

.review__item  span i {
   font-size: 1rem;
   color: var(--secondary-color);
}


/* =============== RESPONSIVE ================ */
@media only screen and (max-width: 768px) {
   .tour__extra-details {
      flex-wrap: wrap;
      row-gap: 1rem;
   }

   .tour__extra-details span {
      font-size: 0.9rem;
   }

   .tour__extra-details span i {
      font-size: 1rem;
   }
}  