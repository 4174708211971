.booking {
   padding      : 2rem;
   border-radius: 0.5rem;
   border       : 1px solid var(--secondary-color);
   position     : sticky;
   top          : 80px;
}

.booking__top h3 {
   font-size  : 1.2rem;
   font-weight: 700;
}

.booking__top h3 span {
   font-size  : 1rem;
   font-weight: 400;
   color      : var(--text-color);
}

.booking__top span {
   font-size  : 1rem;
   font-weight: 600;
   color      : var(--text-color);
}

.booking__top span i {
   color: var(--secondary-color);
}

.booking__top {
   padding-bottom: 1rem;
   border-bottom : 1px solid rgb(229, 231, 235);
}

.booking__form {
   padding-top: 1rem;
}

.booking__form h5 {
   margin-bottom: 1rem;
}

.booking__form input {
   width        : 100%;
   padding      : 0.4rem;
   border-radius: 0.5rem;
   color        : var(--heading-color);
   font-size    : 0.7rem;
   border-bottom: 1px solid rgb(229, 231, 235);
   border       : none;
}

.booking__form input:focus {
   outline: none;
}

.booking__info-form {
   padding: 0.2rem;
   border : 1px solid rgb(229, 231, 235);
}

.booking__bottom li {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.booking__bottom li h5,
.booking__bottom li span {
   font-size: 0.7rem;
   font-weight: 500;
   color: var(--text-color);
}

.total h5,
.total span {
   font-size: 1rem !important;
   font-weight: 700 !important;
   color: var(--heading-color) !important;
}

.booking__bottom Button:hover {
   transform: scale(1.1);
   transition: 0.5s;
}